<template>
  <v-container>
    <v-row>
      <v-col sm="8" offset-sm="2">
        <v-container>
          <v-row>
            <v-col class="pa-6 text-left text-justify">
              <TextHeading :level=3>Platforma Biznesu</TextHeading>

              <div class="my-6">
                Jesteśmy firmą produkcyjno-outsourcingową oferującą szeroką gamę profesjonalnych usług i rozwiązań informatycznych. Firma obsługuje Klientów zarówno z sektora prywatnego, jak i publicznego, a dzięki własnym centrom usług i centrom rozwoju produktów Platforma Biznesu jest w stanie świadczyć swoje usługi na poziomie globalnym i na wszystkich etapach ich rozwoju. Nasi specjaliści pracują nad najnowocześniejszymi rozwiązaniami dla międzynarodowych i lokalnych Klientów z różnych branż.
              </div>
              <div>

                ul. Królowej Korony Polskiej 2/2<br />
                70-485 Szczecin<br />
                Tel (+48) 91 35 10 141<br />
                Fax (+48) 91 35 10 142<br />
                info@platformabiznesu.net<br />
                <br /><br />

                KRS: 0000404732<br />
                NIP: 851-31-61-089<br />
                REGON: 321166829<br />
                Zarejestrowana w Sądzie Rejonowym Szczecin-Centrum w Szczecinie, XIII Wydział Gospodarczy Krajowego Rejestru Sądowego
              </div>


            </v-col>
            <v-col sm="5">
              <contact-form />
              <div class="my-4">
                Przesyłając zapytanie, akceptujesz warunki zapisane w naszej <router-link to="/privacy">polityce prywatności</router-link>.
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import ContactForm from "@/modules/ContactForm";
import TextHeading from "@/modules/TextHeading";
export default {

  //TODO: update form data
  components: {TextHeading, ContactForm}
}
</script>
